<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogLogOut" width="400px">
      <v-card>
        <v-toolbar dense class="elevation-0 primary dark">
          <v-toolbar-title class="white--text">Log Out</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            large
            class="mr-n4"
            dark
            text
            @click="dialogLogOutEmit((Toggle = 1))"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text align="center">
          <v-icon
            size="50px"
            class="mt-4"
            color="primary"
          >mdi-exit-to-app</v-icon>
          <div>Are you sure you want to logout ?</div>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            text
            @click="dialogLogOutEmit((Toggle = 1))"
            >Cancel</v-btn
          >
          <v-btn
            small
            :loading="isLoading"
            class="secondary white--text"
            @click="dialogLogOutEmit((Toggle = 2))"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    StoreObj: Object,
    dialogLogOut: Boolean,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    isLoading: false,
    SnackBarComponent: {},
  }),
  methods: {
    dialogLogOutEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
<style>
.primary {
  color: #d97d54 !important;
}
</style>
