<template>
  <div>
    <Overlay :overlay="overlay" />
    <LogoutDialog :dialogLogOut="dialogLogOut" @clicked="dialogLogOutEmit" />
    <v-app-bar dense app clipped-left color="black" elevation="0">
      <v-img src="@/assets/logo1.png" max-width="170px"></v-img>
      <div style="color: white"><h6>Version 0.0.1</h6></div>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="htmlToImageMethod()"
            color="primary"
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
        <span>Facing Issues Using ServiceWrk ? Click To Report</span>
      </v-tooltip>
      <div style="color: white">{{ $store.getters.get_user_email }}</div>
      <v-btn icon @click="dialogLogOut = true">
        <v-icon color="secondary" large dark>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      width="190px"
      color="secondary"
      dark
    >
      <v-list dark dense>
        <v-list-group
          active-class="grey darken-3"
          link
          v-for="(item, idx) in RouterList"
          :key="idx"
          :append-icon="item.has_child_routes == true ? 'mdi-chevron-down' : ''"
          no-action
          @click="
            $route.name != item.MenuRoute && item.has_child_routes == false
              ? $router.push(item.MenuRoute)
              : ''
          "
        >
          <template v-slot:activator>
            <v-list-item-action class="mr-0">
              <v-icon small color="white">{{ item.MenuICon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="px-0 mx-0" :to="item.MenuRoute" link>
              <v-list-item-title class="white--text">{{
                item.MenuName
              }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item-group link>
            <v-list-item
              v-for="(sitem, i) in item.SubMenu"
              :key="i"
              link
              :to="sitem.MenuRoute"
              @click="master_setting_type = sitem.MenuName"
            >
              <v-icon color="white" class="mx-2" small>{{
                sitem.MenuICon
              }}</v-icon>
              <v-list-item-title class="white--text">{{
                sitem.MenuName
              }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <div ref="home">
      <v-main>
        <router-view :master_setting_type="master_setting_type" />
      </v-main>
    </div>
  </div>
</template>
<script>
import { GetCurrentUser } from "@/mixins/GetCurrentUser.js";
import * as htmlToImage from "html-to-image";
import RouterList from "@/JsonFiles/RouterList.json";
import Overlay from "@/components/Extras/Overlay.vue";
import LogoutDialog from "@/components/Extras/LogoutDialog.vue";
export default {
  mixins: [GetCurrentUser],
  components: {
    Overlay,
    LogoutDialog,
  },
  data: () => ({
    drawer: true,
    overlay: false,
    dialogLogOut: false,
    dialogCreateIssue: false,
    StoreObj: {},
    RouterList: [],
    subMenuVmodel: 0,
    master_setting_type: "Service Types",
  }),
  watch: {
    "$route.name"() {
      this.master_setting_type = "Service Types";
    },
  },
  mounted() {
    this.RouterList = RouterList;
    this.GetCurrentUser();
  },
  methods: {
    htmlToImageMethod() {
      this.overlay = true;
      this.$forceUpdate();
      this.selectedFilesFromDrop = [];
      var self = this;
      setTimeout(() => {
        htmlToImage.toBlob(this.$refs.home).then(function (blob) {
          blob.name = `ScreenShot${new Date().getTime()}.png`;
          self.selectedFilesFromDrop.push(blob);
          let object = {
            selectedFilesFromDrop: self.selectedFilesFromDrop,
            routeName: self.$route.name,
          };
          self.$store.commit("SET_ISSUE_DETAILS", object);
          self.$store.commit("SET_PREVIOUS_ROUTE", self.$route.name);
          self.$router.push("ReportIssue");
        });
      }, 2000);
    },
    dialogCreateIssueEmit() {
      this.dialogCreateIssue = false;
      this.selectedFilesFromDrop = [];
    },
    reportissue() {
      window.open("http://www.bugtrakr.com/BugReportForm/SRVCEWRK");
    },
    dialogLogOutEmit(Toggle) {
      this.dialogLogOut = false;
      if (Toggle == 2) {
        this.$router.push("/");
      }
    },
  },
};
</script>
