import { Get_Current_User_Details } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetCurrentUser = {
  data() {
    return {
      overlay: false,
      RouterList: [],
      get_Current_User_DetailsObject: {},
    };
  },
  methods: {
    async GetCurrentUser() {
      this.overlay = true;
      let result = await API.graphql(
        graphqlOperation(Get_Current_User_Details, {
          input: {
            user_email_id: this.$store.getters.get_user_email,
          },
        })
      );
      this.$store.commit(
        "SET_CURRENTUSER_DETAILS",
        JSON.parse(result.data.Get_Current_User_Details).data.items[0]
      );
      if (this.$route.name == "LandingPage") {
        this.$router.push("/Organization");
      }
      this.overlay = false;
    },
  },
};
